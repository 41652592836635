import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { colors } from '../config/theme';
import { createQuery } from '../apiClients/queryClient';
import {
  Button,
  Description,
  Input,
  LegaleseLogo,
  Page,
  Section,
  Title,
  TypewriterComponent
} from './Shared';

function QueryForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ body: '' });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onClick = async (e: React.MouseEvent) => {
    e.preventDefault();

    if (formData.body) {
      // check if body form val is real body
      if (
        formData.body === '' ||
        formData.body === undefined ||
        formData.body === null
      ) {
        alert('A question is required.');
        setSubmitted(false);
      } else {
        setSubmitted(true);
        const query = await createQuery(formData.body);
        if (query?.error) {
          console.log('Error!', query.error);
        } else {
          console.log('query from backend', query);
          navigate(`/q/${query.id}?question=${query.body}`);
        }
        setSubmitted(false);
      }
    }
    // clear form
  };

  return (
    <CenterPage>
      <Section>
        <LegaleseLogo />
        <TypewriterComponent label="Legalese." endLabel="Legalese." />
        <Description>A legal q&a assistant</Description>
        {submitted ? (
          <Description>Waiting...</Description>
        ) : (
          <>
            <Input
              id="body"
              name="body"
              placeholder="Ask a tax related question..."
              onChange={handleChange}
              rows={6}
            />
            <Button onClick={onClick} disabled={!formData?.body}>
              Ask Question
            </Button>
          </>
        )}
      </Section>
    </CenterPage>
  );
}

export default QueryForm;

const CenterPage = styled(Page)`
  place-items: center;
  height: 100vh;
`;

//https://css-tricks.com/snippets/css/typewriter-effect/
const TypingAnimation = keyframes`
   from { width: 0 }
  to { width: 100% }
`;

/* The typewriter cursor effect */
const BlinkCaret = keyframes`
  from, to { border-color: transparent }
  50% { border-color: ${colors.blue[5]}; }
`;

const Headline = styled(Title)`
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 4px solid ${colors.blue[5]}; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 4px; /* Adjust as needed */
  width: min-content;
  animation: ${TypingAnimation} 3.5s steps(40, end),
    ${BlinkCaret} 0.75s step-end infinite;
`;
