import { showDocument } from '../apiClients/documentClient';

export const remoteDocumentEffect = (id: string) => (props: any) => {
  const { setSelf } = props;
  const loadRemote = async () => {
    try {
      const document = await showDocument(id);
      if (document.error) {
        console.warn('document not found with id', id);
      } else {
        setSelf(document);
      }
    } catch (e) {
      console.error('error fetching document', id, e);
    }
  };
  loadRemote();
};
