export const serverUrl =
  process.env.REACT_APP_SERVER_URL || 'http://localhost:80';
console.log('serverUrl', serverUrl);

export const createQuery = async (body: string) => {
  const response = await fetch(serverUrl + '/queries', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      body
    })
  });
  if (response.status === 200) {
    return response.json();
  } else {
    return { error: 'broken' };
  }
};

export const showQuery = async (id: string) => {
  const response = await fetch(`${serverUrl}/queries/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (response.status === 200) {
    return response.json();
  } else {
    return { error: 'broken' };
  }
};
