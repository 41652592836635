import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { Document } from './components/Document';

import QueryForm from './components/QueryForm';
import QueryResult from './components/QueryResult';

const router = createBrowserRouter([
  {
    path: '/',
    element: <QueryForm />
  },
  {
    path: '/q/:id',
    element: <QueryResult />
  },
  {
    path: '/d/:id',
    element: <Document />
  }
]);

function App() {
  return (
    <RecoilRoot>
      <RouterProvider router={router} />
    </RecoilRoot>
  );
}

export default App;
