import styled, { keyframes } from 'styled-components';
import React, { useCallback, useEffect, useState } from 'react';
import { colors } from '../config/theme';
import { Link as RRLink } from 'react-router-dom';

export const Page = styled.div`
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.grey[0]};
  height: inherit;
`;

export const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: inherit;
`;

export const CenterContainer = styled.div`
  padding: 24px;
  max-width: 512px;
  min-width: 300px;
  width: -moz-available;
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  gap: 16px;
`;

type SectionProps = {
  children?: React.ReactNode;
};
export const Section = (props: SectionProps) => {
  return (
    <SectionContainer>
      <CenterContainer>{props.children}</CenterContainer>
    </SectionContainer>
  );
};

export const QueryContainer = styled.div`
  width: 100%;
  text-align: left;
  padding: 16px;
  box-sizing: border-box;
  /* border: 1px solid ${colors.grey[8]}; */
  background-color: ${colors.grey[9]};
  color: ${colors.grey[2]};
  border-radius: 8px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
`;

export const ResponseBodyContainer = styled(QueryContainer)`
  /* border: 1px solid ${colors.blue[8]}; */
  background-color: ${colors.blue[10]};
  color: ${colors.blue[2]};
`;

export const Title = styled.div`
  font-size: 48px;
  line-height: 60px;
  color: ${colors.grey[1]};
  font-weight: bold;
`;

export const Description = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: ${colors.grey[5]};
  font-weight: bold;
  text-align: center;
  max-width: 800px;
`;

export const Button = styled.button`
  padding: 10px 20px;
  font-size: 20px;
  line-height: 24px;
  background-color: ${colors.blue[5]};
  color: ${colors.grey[10]};
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background-color: ${colors.blue[2]};
    outline: none;
  }

  &:disabled {
    cursor: auto;
    color: ${colors.grey[5]};
    background-color: ${colors.grey[8]};
  }
`;

export const Input = styled.textarea`
  padding: 12px 16px;
  box-sizing: border-box;
  font-size: 20px;
  border: 2px solid ${colors.grey[7]};
  border-radius: 5px;
  width: 100%;
  max-height: 50vh;
  min-height: 10vh;
  resize: vertical;
  &:hover,
  &:focus,
  &:active {
    border-color: ${colors.blue[5]};
    border-width: 2px;
    outline: none;
  }
`;

const Logo = styled.img`
  height: 75px;
  width: auto;
`;

export const LegaleseLogo = () => <Logo src="/legalese.svg" alt="Legalese" />;

// form with centered inputs
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
`;

export const Link = styled(RRLink)`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.grey[1]};
  text-decoration: none;
  &:hover {
    color: ${colors.grey[6]};
  }
`;

const spinnerKeyframes = keyframes`
to{transform: translate(-50%, -55%) rotate(-45deg) rotateY(360deg)   }
`;

export const LoadingSpinner = styled.span`
  position: relative;
  width: 120px;
  height: 140px;
  background-image: radial-gradient(
      circle 30px,
      ${colors.grey[4]} 100%,
      transparent 0
    ),
    radial-gradient(circle 5px, ${colors.grey[4]} 100%, transparent 0),
    radial-gradient(circle 5px, ${colors.grey[4]} 100%, transparent 0),
    linear-gradient(${colors.grey[4]} 20px, transparent 0);
  background-position: center 127px, 94px 102px, 16px 18px, center 114px;
  background-size: 60px 60px, 10px 10px, 10px 10px, 4px 14px;
  background-repeat: no-repeat;
  z-index: 10;
  perspective: 500px;

  &::before {
    content: '';
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid ${colors.grey[4]};
    left: 50%;
    top: 50%;
    transform: translate(-50%, -55%) rotate(-45deg);
    border-right-color: transparent;
    box-sizing: border-box;
  }

  &::after {
    content: '';
    position: absolute;
    height: 80px;
    width: 80px;
    transform: translate(-50%, -55%) rotate(-45deg) rotateY(0deg);
    left: 50%;
    top: 50%;
    box-sizing: border-box;
    border: 7px solid ${colors.blue[5]};
    border-radius: 50%;
    animation: ${spinnerKeyframes} 1s linear infinite;
  }
`;

type TypewriterComponentProps = {
  label: string;
  endLabel: string;
};

const TYPEWRITER_SPEED = 125;
export const TypewriterComponent = (props: TypewriterComponentProps) => {
  const { label, endLabel } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [writtenLabel, setWrittenLabel] = useState('');

  useEffect(() => {
    writeNextLetter();
  }, []);

  useEffect(() => {
    if (currentIndex <= label?.length) {
      setWrittenLabel(label?.substring(0, currentIndex));
      setTimeout(writeNextLetter, TYPEWRITER_SPEED);
    } else if (currentIndex > label?.length) {
      setWrittenLabel(endLabel);
    }
  }, [currentIndex]);

  const writeNextLetter = () => {
    setCurrentIndex(currentIndex + 1);
  };

  return <Title>{writtenLabel}</Title>;
};

export const LeftAlignedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

const HeaderContainer = styled(SectionContainer)`
  height: auto;
  align-items: end;
  gap: 16px;
  padding: 16px;
  box-sizing: border-box;
`;

export const Header = () => {
  return (
    <HeaderContainer>
      <LegaleseLogo />
      <Title>Legalese.</Title>
    </HeaderContainer>
  );
};
