import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { showDocument } from '../apiClients/documentClient';
import { colors } from '../config/theme';
import {
  Description,
  LeftAlignedContainer,
  Header,
  Page,
  Section,
  Link
} from './Shared';

export type Document = {
  source_section_id: string;
  content: string;
  publication_title: string;
  source_url: string;
  tag_list_json: [string];
  doc_id: string;
};

const DocumentContainer = styled(LeftAlignedContainer)`
  background-color: ${colors.grey[10]};
  border-radius: 8px;
  width: inherit;
  padding: 12px;
  box-sizing: border-box;
`;

const DocumentIdPill = styled.div`
  color: white;
  background-color: ${colors.blue[7]};
  padding: 4px 8px;
  border-radius: 4px;
`;

export const Document = () => {
  const params = useParams();
  const id = params.id;
  const [document, setDocument] = useState<Document>();
  useEffect(() => {
    const getDocument = async () => {
      if (id) {
        const document = await showDocument(id);
        if (document?.doc_id) {
          setDocument(document);
        }
      }
    };
    getDocument();
  }, [id]);
  return (
    <Page>
      <Header />
      <Section>
        <LeftAlignedContainer>
          {document?.source_url && (
            <Link to={document.source_url}>
              <Description>{document?.publication_title}</Description>
            </Link>
          )}
          <DocumentContainer>{document?.content}</DocumentContainer>
        </LeftAlignedContainer>
      </Section>
    </Page>
  );
};

const LeftDescription = styled(Description)`
  text-align: left;
`;
