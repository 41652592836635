import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { showQuery } from '../apiClients/queryClient';
import {
  Page,
  QueryContainer,
  ResponseBodyContainer,
  Section,
  Link,
  LoadingSpinner,
  Description,
  LeftAlignedContainer,
  Header
} from './Shared';
import useWebSocket from 'react-use-websocket';
import styled from 'styled-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { documentStateFamily, highlightedIndexState } from '../state/atom';
import { SOCKET_URL } from '../config/default';
import { colors } from '../config/theme';
import { Document } from './Document';

type Query = {
  id?: number;
  body?: string;
  responses?: string[];
  status?: string;
};

const QueryResult = () => {
  const [params, setParams] = useSearchParams();
  const question = params.get('question');
  const { id } = useParams();
  const [query, setQuery] = useState<Query | undefined>();

  const { lastMessage } = useWebSocket(SOCKET_URL + `/ws`, {
    queryParams: { id: id || 1 }
  });

  useEffect(() => {
    if (lastMessage?.data) {
      setQuery(JSON.parse(lastMessage.data));
    }
  }, [lastMessage]);

  useEffect(() => {
    async function fetchQuery() {
      if (id) {
        const query = await showQuery(id);
        if (query?.id) {
          setParams({});
          setQuery(query);
        }
      }
    }
    fetchQuery();
  }, [id]);

  return (
    <Page>
      <Header />
      <Section>
        <LeftAlignedContainer>
          <Description>Question</Description>
          <QueryContainer>{query?.body || question}</QueryContainer>
          <Description>Responses</Description>
          {!query || query?.status === 'pending' ? (
            <WaitingForResponse />
          ) : undefined}

          {(query?.responses || [])
            ?.filter((response: any) => response.type === 'og')
            .map((response: any) => (
              <ResponseReceived
                key={`response-${response.id}`}
                query={query}
                response={response}
              />
            ))}
        </LeftAlignedContainer>
        <Footer>
          <Link to="/">Ask another question?</Link>
        </Footer>
      </Section>
    </Page>
  );
};

const Footer = styled.div`
  padding: 24px;
  font-size: 24px;
  line-height: 30px;
`;

interface ResponseProps {
  response: any;
  query: any;
}

const WaitingForResponse = () => {
  return (
    <Section>
      <LoadingSpinner />
      <Description>Preparing responses to your question...</Description>
    </Section>
  );
};

const ResponseTypePill = styled.div`
  color: white;
  background-color: ${colors.blue[7]};
  padding: 4px 8px;
  border-radius: 4px;
`;

const Subdescription = styled(Description)`
  font-size: 16px;
  font-weight: bold;
`;

const ResponseContainer = styled(LeftAlignedContainer)`
  background-color: ${colors.grey[10]};
  border-radius: 8px;
  width: inherit;
  padding: 12px;
  box-sizing: border-box;
`;

const HighlightableText = styled.span`
  &:hover {
    background-color: ${colors.blue[9]};
    border-radius: 4px;
  }
`;

const ResponseBody = (props: ResponseProps) => {
  const { response } = props;
  const setHighlightIndex = useSetRecoilState(highlightedIndexState);
  const [body, setBody] = useState(<>{response?.body}</>);
  useEffect(() => {
    let matches = [...response?.body?.matchAll(/\(\d+\)/g)];
    let lastPoint = 0;
    if (matches.length > 0) {
      const newBody = matches.reduce((acc, match, matchPosition) => {
        const text = response?.body.substring(lastPoint, match['index']);
        const newLastPoint = match['index'] + match[0].length;
        const link = response?.body.substring(match['index'], newLastPoint);
        const index = match[0].slice(1, -1);
        const id = response?.documents?.[index - 1];
        acc.push(
          <HighlightableText
            onMouseEnter={() => setHighlightIndex(id)}
            onMouseLeave={() => setHighlightIndex(undefined)}
            key={`text-${matchPosition}-${index}`}
          >
            {text}
          </HighlightableText>
        );
        acc.push(
          <Link key={`doc-link-${matchPosition}-${index}`} to={`/d/${id}`}>
            {link}
          </Link>
        );
        lastPoint = newLastPoint;
        return acc;
      }, []);
      setBody(newBody);
    }
  }, [response?.body]);

  return <ResponseBodyContainer>{body}</ResponseBodyContainer>;
};

const ResponseReceived = (props: ResponseProps) => {
  const { response } = props;
  return (
    <ResponseContainer>
      {/* <ResponseTypePill>{response.type}</ResponseTypePill> */}
      <ResponseBody response={response} query="" />
      {response?.documents.length > 0 && (
        <>
          <Subdescription>Sources</Subdescription>

          {response?.documents.map((docId: string, index: any) => (
            <DocumentItem key={`doc-${index}-${docId}`} docId={docId} />
          ))}
        </>
      )}
    </ResponseContainer>
  );
};

type DocumentItemProps = {
  docId: string;
};

const HighlightedLink = styled<any>(Link)`
  background-color: ${(props: any) =>
    props?.highlight == 'true' ? colors.blue[9] : 'none'};
`;

const BoldLink = styled<any>(Link)`
  color: ${colors.grey[4]};
  font-weight: bold;
`;

const DocumentItem: React.FunctionComponent<DocumentItemProps> = (props) => {
  const { docId } = props;
  const highlightedIndex = useRecoilValue(highlightedIndexState);

  const document = useRecoilValue<Document>(documentStateFamily(docId));
  return (
    <div>
      <div>
        <BoldLink to={document.source_url}>
          {document?.publication_title}
        </BoldLink>
      </div>
      <HighlightedLink
        to={document.source_url}
        highlight={(highlightedIndex === docId).toString()}
      >
        "{document?.content}"
      </HighlightedLink>
    </div>
  );
};

export default QueryResult;
