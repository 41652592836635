import { serverUrl } from './queryClient';

export const showDocument = async (id: string) => {
  const response = await fetch(`${serverUrl}/documents/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (response.status === 200) {
    const document = await response.json();
    return document;
  } else {
    return { error: 'broken' };
  }
};
