export const colors = {
  grey: {
    0: 'hsl(212, 28%, 9%)',
    1: 'hsl(210, 24%, 16%)',
    2: 'hsl(209, 20%, 25%)',
    3: 'hsl(209, 18%, 30%)',
    4: 'hsl(209, 14%, 37%)',
    5: 'hsl(211, 12%, 43%)',
    6: 'hsl(211, 10%, 53%)',
    7: 'hsl(211, 13%, 65%)',
    8: 'hsl(210, 16%, 82%)',
    9: 'hsl(214, 15%, 95%)',
    10: 'hsl(216, 33%, 97%)'
  },
  green: {
    1: 'hsl(140, 71%, 8%)',
    2: 'hsl(141, 70%, 12%)',
    3: 'hsl(141, 66%, 22%)',
    4: 'hsl(144, 62%, 31%)',
    5: 'hsl(144, 59%, 42%)',
    6: 'hsl(148, 54%, 58%)',
    7: 'hsl(151, 59%, 67%)',
    8: 'hsl(151, 61%, 72%)',
    9: 'hsl(153, 64%, 87%)',
    10: 'hsl(153, 66%, 92%)'
  },
  orange: {
    1: 'hsl(23, 94%, 9%)',
    2: 'hsl(24, 91%, 16%)',
    3: 'hsl(24, 89%, 28%)',
    4: 'hsl(26, 86%, 38%)',
    5: 'hsl(28, 83%, 46%)',
    6: 'hsl(28, 84%, 54%)',
    7: 'hsl(30, 84%, 64%)',
    8: 'hsl(33, 88%, 74%)',
    9: 'hsl(34, 89%, 84%)',
    10: 'hsl(37, 91%, 91%)'
  },
  blue: {
    1: 'hsl(216, 100%, 8%)',
    2: 'hsl(216, 98%, 22%)',
    3: 'hsl(219, 95%, 32%)',
    4: 'hsl(219, 91%, 38%)',
    5: 'hsl(222, 91%, 61%)',
    6: 'hsl(222, 94%, 71%)',
    7: 'hsl(224, 98%, 76%)',
    8: 'hsl(227, 98%, 84%)',
    9: 'hsl(227, 100%, 91%)',
    10: 'hsl(227, 100%, 95%)'
  },
  red: {
    1: 'hsl(340, 100%, 11%)',
    2: 'hsl(342, 98%, 23%)',
    3: 'hsl(344, 97%, 33%)',
    4: 'hsl(346, 94%, 43%)',
    5: 'hsl(349, 91%, 63%)',
    6: 'hsl(351, 93%, 72%)',
    7: 'hsl(354, 96%, 77%)',
    7: 'hsl(356, 98%, 85%)',
    9: 'hsl(358, 100%, 93%)'
  }
};
