import createTheme from '@mui/material/styles/createTheme';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { orange, blue, grey } from '@mui/material/colors';

// default theme: https://mui.com/material-ui/customization/default-theme/

const theme = createTheme({
  palette: {
    background: {
      paper: '#fff',
      default: grey[50],
      textBox: grey[100]
    },
    text: {
      primary: grey[900],
      secondary: grey[50],
      black: grey[900],
      white: grey[50],
      grey: grey[500],
      lightGrey: grey[300]
    },
    success: {
      main: '#009688'
    },
    primary: {
      main: '#506CF0',
      light: '#506CF0',
      dark: '#506CF0'
    },
    secondary: {
      main: orange[800],
      light: orange[500],
      dark: orange[900]
    },
    action: {
      disabledBackground: '#506CF0'
    }
  },
  // breakpoints: {
  //   values: {
  //     mobile: 0,
  //     tablet: 640,
  //     laptop: 1024,
  //     desktop: 1200
  //   }
  // },
  typography: {
    inactive: {
      color: '#gray',
      fontWeight: 600
    },
    secondary: {
      color: blue[100]
    },
    textBlock: {
      color: '#black',
      fontWeight: 600
    },
    microtext: {
      color: grey[50],
      fontSize: '5px'
    }
  },

  components: {
    // Name of the component
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true // No more ripple, on the whole application 💣!
      }
    },
    ToolBar: {
      defaultProps: {
        backgroundColor: 'red'
      }
    },
    NavBar: {
      defaultProps: {
        backgroundColor: 'red'
      }
    },
    Box: {
      defaultProps: {
        borderRadius: '40%'
      }
    },
    Button: {
      defaultProps: {
        borderRadius: '40%'
      }
    }
  }
});

export default theme;
