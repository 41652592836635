import { atom, atomFamily } from 'recoil';
import { remoteDocumentEffect } from './effects';

export const documentStateFamily = atomFamily({
  key: 'documentStateFamily',
  effects: (id) => (id !== undefined ? [remoteDocumentEffect(id)] : [])
});

export const highlightedIndexState = atom({
  key: 'highlightedIndexState',
  default: undefined
});
